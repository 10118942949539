/**
 * @generated SignedSource<<06f12a8d46af0884ce681edbd538549a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssignmentStatus = "Assigned" | "Either" | "Empty";
export type AssignmentsExportEnum = "AssignmentsExport";
export type AssignmentsWithSupersReportEnum = "AssignmentsWithSupersReport";
export type AvailabilityReportEnum = "AvailabilityReport";
export type DocumentBuilderFileFormatKind = "excel" | "pdf";
export type GapReportEnum = "GapReport";
export type GroupedByStagesNextAssignmentReportEnum = "GroupedByStagesNextAssignmentReport";
export type GroupedByStagesReportEnum = "GroupedByStagesReport";
export type GroupedByStagesWithContactInfoReportEnum = "GroupedByStagesWithContactInfoReport";
export type GroupedResourceDayReportEnum = "GroupedResourceDayReport";
export type GroupedResourceReportEnum = "GroupedResourceReport";
export type Staffing = "EitherStaffed" | "FullyStaffed" | "NotFullyStaffed";
export type UnstaffedPositionsReportEnum = "UnstaffedPositionsReport";
export type UtilizationStatus = "FullyAllocated" | "NotAllocated" | "Overallocated" | "Underallocated";
export type ExecuteDocumentBuilderInput = {
  clientMutationId?: string | null;
  fileFormatKind: DocumentBuilderFileFormatKind;
  input: DocumentBuilderInput;
};
export type DocumentBuilderInput = {
  AssignmentsExport?: AssignmentsExportInput | null;
  AssignmentsWithSupersReport?: AssignmentsWithSupersReportInput | null;
  AvailabilityReport?: AvailabilityReportInput | null;
  CurrentFieldStaffLocationReport?: CurrentFieldStaffLocationReportInput | null;
  GapReport?: GapReportInput | null;
  GroupedByStagesNextAssignmentReport?: GroupedByStagesNextAssignmentReportInput | null;
  GroupedByStagesReport?: GroupedByStagesReportInput | null;
  GroupedByStagesWithContactInfoReport?: GroupedByStagesWithContactInfoReportInput | null;
  GroupedResourceDayReport?: GroupedResourceDayReportInput | null;
  GroupedResourceReport?: GroupedResourceReportInput | null;
  PeopleExport?: PeopleExportInput | null;
  ProjectReport?: ProjectReportInput | null;
  TagsExport?: TagsExportInput | null;
  UnstaffedPositionsReport?: UnstaffedPositionsReportInput | null;
};
export type GroupedByStagesNextAssignmentReportInput = {
  backgroundColorOpt?: string | null;
  filterByAssignmentRolesOpt?: ReadonlyArray<string> | null;
  filterByAssignmentTagsOpt?: ReadonlyArray<string> | null;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByExecutivesOpt?: ReadonlyArray<string> | null;
  filterByPeopleOpt?: ReadonlyArray<string> | null;
  filterByProjectStageRefsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  kind: GroupedByStagesNextAssignmentReportEnum;
  scenarioRef: string;
  titleOpt?: string | null;
  toOpt?: any | null;
  useAlternatingRowColors: boolean;
};
export type GapReportInput = {
  backgroundColorOpt?: string | null;
  filterByAssignmentRolesOpt?: ReadonlyArray<string> | null;
  filterByAssignmentTagsOpt?: ReadonlyArray<string> | null;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByExecutivesOpt?: ReadonlyArray<string> | null;
  filterByPeopleOpt?: ReadonlyArray<string> | null;
  filterByProjectStageRefsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  kind: GapReportEnum;
  scenarioRef: string;
  titleOpt?: string | null;
  toOpt?: any | null;
  useAlternatingRowColors: boolean;
};
export type GroupedResourceDayReportInput = {
  backgroundColorOpt?: string | null;
  filterByAssignmentRolesOpt?: ReadonlyArray<string> | null;
  filterByAssignmentTagsOpt?: ReadonlyArray<string> | null;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByExecutivesOpt?: ReadonlyArray<string> | null;
  filterByPeopleOpt?: ReadonlyArray<string> | null;
  filterByProjectStageRefsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  kind: GroupedResourceDayReportEnum;
  scenarioRef: string;
  titleOpt?: string | null;
  toOpt?: any | null;
  useAlternatingRowColors: boolean;
};
export type AvailabilityReportInput = {
  backgroundColorOpt?: string | null;
  filterByAssignmentRolesOpt?: ReadonlyArray<string> | null;
  filterByAssignmentTagsOpt?: ReadonlyArray<string> | null;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByExecutivesOpt?: ReadonlyArray<string> | null;
  filterByPeopleOpt?: ReadonlyArray<string> | null;
  filterByProjectStageRefsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  kind: AvailabilityReportEnum;
  scenarioRef: string;
  titleOpt?: string | null;
  toOpt?: any | null;
  useAlternatingRowColors: boolean;
};
export type PeopleExportInput = {
  kind: GapReportEnum;
  name?: string | null;
};
export type CurrentFieldStaffLocationReportInput = {
  backgroundColorOpt?: string | null;
  filterByAssignmentRolesOpt?: ReadonlyArray<string> | null;
  filterByAssignmentTagsOpt?: ReadonlyArray<string> | null;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByExecutivesOpt?: ReadonlyArray<string> | null;
  filterByPeopleOpt?: ReadonlyArray<string> | null;
  filterByProjectStageRefsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  kind: string;
  scenarioRef: string;
  titleOpt?: string | null;
  toOpt?: any | null;
  useAlternatingRowColors: boolean;
};
export type TagsExportInput = {
  kind: string;
  name?: string | null;
};
export type GroupedResourceReportInput = {
  backgroundColorOpt?: string | null;
  filterByAssignmentRolesOpt?: ReadonlyArray<string> | null;
  filterByAssignmentTagsOpt?: ReadonlyArray<string> | null;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByExecutivesOpt?: ReadonlyArray<string> | null;
  filterByPeopleOpt?: ReadonlyArray<string> | null;
  filterByProjectStageRefsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  kind: GroupedResourceReportEnum;
  scenarioRef: string;
  titleOpt?: string | null;
  toOpt?: any | null;
  useAlternatingRowColors: boolean;
};
export type AssignmentsExportInput = {
  kind: AssignmentsExportEnum;
  personFiltersOpt?: PersonOnAssignmentFiltersInput | null;
  projectFiltersOpt?: ProjectWithAssignmentsFiltersInput | null;
  scenarioRef: string;
};
export type PersonOnAssignmentFiltersInput = {
  assignmentInDateRange?: LocalDateRangeFilterInput | null;
  assignmentStatus?: AssignmentStatus | null;
  assignmentTags?: ReadonlyArray<string> | null;
  currentlyAssignedAssignmentRoles?: ReadonlyArray<string> | null;
  divisions?: ReadonlyArray<string> | null;
  email?: string | null;
  executives?: ReadonlyArray<string> | null;
  gapDays?: IntRangeFilterInput | null;
  ids?: ReadonlyArray<string> | null;
  jobTitles?: ReadonlyArray<string> | null;
  laborBurdenMultiplier?: BigDecimalRangeFilterInput | null;
  name?: string | null;
  regions?: ReadonlyArray<string> | null;
  salary?: BigDecimalRangeFilterInput | null;
  skillFilters?: ReadonlyArray<SkillFilter> | null;
  startDate?: LocalDateRangeFilterInput | null;
  utilizationStatuses?: ReadonlyArray<UtilizationStatus> | null;
  utilizationWindow?: UtilizationWindowInput | null;
};
export type LocalDateRangeFilterInput = {
  from?: any | null;
  to?: any | null;
};
export type BigDecimalRangeFilterInput = {
  from?: number | null;
  to?: number | null;
};
export type UtilizationWindowInput = {
  utilizationEnd: any;
  utilizationStart: any;
};
export type IntRangeFilterInput = {
  from?: number | null;
  to?: number | null;
};
export type SkillFilter = {
  hasSkillOpt?: boolean | null;
  maxOpt?: number | null;
  minOpt?: number | null;
  skillId: string;
};
export type ProjectWithAssignmentsFiltersInput = {
  assignmentStatus?: AssignmentStatus | null;
  budgetedLaborCosts?: BigDecimalRangeFilterInput | null;
  divisions?: ReadonlyArray<string> | null;
  executives?: ReadonlyArray<string> | null;
  generalConditionsPercentage?: BigDecimalRangeFilterInput | null;
  ids?: ReadonlyArray<string> | null;
  inDateRange?: LocalDateRangeFilterInput | null;
  name?: string | null;
  regions?: ReadonlyArray<string> | null;
  skillIds?: ReadonlyArray<string> | null;
  staffing?: Staffing | null;
  stages?: ReadonlyArray<string> | null;
  volume?: BigDecimalRangeFilterInput | null;
};
export type GroupedByStagesWithContactInfoReportInput = {
  backgroundColorOpt?: string | null;
  filterByAssignmentRolesOpt?: ReadonlyArray<string> | null;
  filterByAssignmentTagsOpt?: ReadonlyArray<string> | null;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByExecutivesOpt?: ReadonlyArray<string> | null;
  filterByPeopleOpt?: ReadonlyArray<string> | null;
  filterByProjectStageRefsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  kind: GroupedByStagesWithContactInfoReportEnum;
  scenarioRef: string;
  titleOpt?: string | null;
  toOpt?: any | null;
  useAlternatingRowColors: boolean;
};
export type UnstaffedPositionsReportInput = {
  backgroundColorOpt?: string | null;
  filterByAssignmentRolesOpt?: ReadonlyArray<string> | null;
  filterByAssignmentTagsOpt?: ReadonlyArray<string> | null;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByExecutivesOpt?: ReadonlyArray<string> | null;
  filterByPeopleOpt?: ReadonlyArray<string> | null;
  filterByProjectStageRefsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  kind: UnstaffedPositionsReportEnum;
  scenarioRef: string;
  titleOpt?: string | null;
  toOpt?: any | null;
  useAlternatingRowColors: boolean;
};
export type ProjectReportInput = {
  backgroundColorOpt?: string | null;
  filterByAssignmentRolesOpt?: ReadonlyArray<string> | null;
  filterByAssignmentTagsOpt?: ReadonlyArray<string> | null;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByExecutivesOpt?: ReadonlyArray<string> | null;
  filterByPeopleOpt?: ReadonlyArray<string> | null;
  filterByProjectStageRefsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  kind: string;
  scenarioRef: string;
  titleOpt?: string | null;
  toOpt?: any | null;
  useAlternatingRowColors: boolean;
};
export type AssignmentsWithSupersReportInput = {
  backgroundColorOpt?: string | null;
  filterByAssignmentRolesOpt?: ReadonlyArray<string> | null;
  filterByAssignmentTagsOpt?: ReadonlyArray<string> | null;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByExecutivesOpt?: ReadonlyArray<string> | null;
  filterByPeopleOpt?: ReadonlyArray<string> | null;
  filterByProjectStageRefsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  kind: AssignmentsWithSupersReportEnum;
  scenarioRef: string;
  titleOpt?: string | null;
  toOpt?: any | null;
  useAlternatingRowColors: boolean;
};
export type GroupedByStagesReportInput = {
  backgroundColorOpt?: string | null;
  filterByAssignmentRolesOpt?: ReadonlyArray<string> | null;
  filterByAssignmentTagsOpt?: ReadonlyArray<string> | null;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByExecutivesOpt?: ReadonlyArray<string> | null;
  filterByPeopleOpt?: ReadonlyArray<string> | null;
  filterByProjectStageRefsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  kind: GroupedByStagesReportEnum;
  scenarioRef: string;
  titleOpt?: string | null;
  toOpt?: any | null;
  useAlternatingRowColors: boolean;
};
export type exportAssignmentsButton_executeDocumentBuilderMutation$variables = {
  input: ExecuteDocumentBuilderInput;
};
export type exportAssignmentsButton_executeDocumentBuilderMutation$data = {
  readonly DocumentBuilder: {
    readonly executeDocumentBuilder: {
      readonly file: {
        readonly url: string | null;
      };
    } | null;
  };
};
export type exportAssignmentsButton_executeDocumentBuilderMutation = {
  response: exportAssignmentsButton_executeDocumentBuilderMutation$data;
  variables: exportAssignmentsButton_executeDocumentBuilderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "exportAssignmentsButton_executeDocumentBuilderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentBuilderMutations",
        "kind": "LinkedField",
        "name": "DocumentBuilder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ExecuteDocumentBuilderPayload",
            "kind": "LinkedField",
            "name": "executeDocumentBuilder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "exportAssignmentsButton_executeDocumentBuilderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentBuilderMutations",
        "kind": "LinkedField",
        "name": "DocumentBuilder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ExecuteDocumentBuilderPayload",
            "kind": "LinkedField",
            "name": "executeDocumentBuilder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "feba7d7d2fd20f3db3a6e39c3eaff136",
    "id": null,
    "metadata": {},
    "name": "exportAssignmentsButton_executeDocumentBuilderMutation",
    "operationKind": "mutation",
    "text": "mutation exportAssignmentsButton_executeDocumentBuilderMutation(\n  $input: ExecuteDocumentBuilderInput!\n) {\n  DocumentBuilder {\n    executeDocumentBuilder(input: $input) {\n      file {\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f16c65f02119d8d07ee31a199d73e47";

export default node;
