/**
 * @generated SignedSource<<5829f61d54556216790014b429f9d9d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonDetailsControl_Refetch$variables = {
  after?: string | null;
  filterByPerson?: string | null;
  filterByScenario?: string | null;
  first?: number | null;
};
export type PersonDetailsControl_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"personDetailsControl_AssignmentListFragment">;
};
export type PersonDetailsControl_Refetch = {
  response: PersonDetailsControl_Refetch$data;
  variables: PersonDetailsControl_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByPerson"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByScenario"
  },
  {
    "defaultValue": 50,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filterByPerson",
    "variableName": "filterByPerson"
  },
  {
    "kind": "Variable",
    "name": "filterByScenario",
    "variableName": "filterByScenario"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonDetailsControl_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "personDetailsControl_AssignmentListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PersonDetailsControl_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentsQueries",
        "kind": "LinkedField",
        "name": "Assignments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AssignmentTypesConnection",
            "kind": "LinkedField",
            "name": "Assignments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentTypesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Assignment",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "time",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "weight",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isDeactivated",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "person",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentRole",
                        "kind": "LinkedField",
                        "name": "validAssignmentRoles",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "filterByPerson",
              "filterByScenario"
            ],
            "handle": "connection",
            "key": "PersonDetailsControl_Assignments",
            "kind": "LinkedHandle",
            "name": "Assignments"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "df6a665ac92af6c04873c9d4b741d86f",
    "id": null,
    "metadata": {},
    "name": "PersonDetailsControl_Refetch",
    "operationKind": "query",
    "text": "query PersonDetailsControl_Refetch(\n  $after: String\n  $filterByPerson: ID\n  $filterByScenario: ID\n  $first: Int = 50\n) {\n  ...personDetailsControl_AssignmentListFragment_zhBcb\n}\n\nfragment AssignmentProjectCard_AssignmentFragment on Assignment {\n  id\n  weight\n  project {\n    name\n    id\n    isDeactivated\n  }\n  person {\n    name\n    id\n  }\n  startDate\n  endDate\n  validAssignmentRoles {\n    id\n    name\n  }\n}\n\nfragment personDetailsControl_AssignmentListFragment_zhBcb on Query {\n  Assignments {\n    Assignments(first: $first, after: $after, filterByPerson: $filterByPerson, filterByScenario: $filterByScenario) {\n      pageInfo {\n        endCursor\n        hasPreviousPage\n        hasNextPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          time\n          ...AssignmentProjectCard_AssignmentFragment\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9fd6990f0ddfa148ce20795fbaa16973";

export default node;
