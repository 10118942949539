import React from "react";
import styled from "styled-components";
import { PersonCard } from "@components/person-card";
import { AllocationBarProvider } from "@screens/staff-view/parts/allocation-bar/context";
import { AllocationBarComponent } from "@screens/staff-view/parts/AllocationBarComponent";
import { IntervalHeaderComponent } from "@screens/staff-view/parts/IntervalHeaderComponent";
import {
	calculateLaneHeight,
	checkIfAnAllocationChainsWithTheNextOne,
} from "@screens/staff-view/parts/staff-view-part/staff-view-part.utils";
import { type StaffViewPrintProps } from "@screens/staff-view/parts/staff-view-print/staff-view-print.types";
import {
	COLUMN_WIDTH,
	HEADER_MARGIN,
	MARGIN_BETWEEN_PEOPLE,
	SIDEBAR_SIZE,
	SUBHEADER_SIZE,
} from "@screens/staff-view/parts/staff-view.utils";

const StaffViewPrintComponent = ({
	scenario,
	showSubheaders,
	cumulativeSubheaderOffset,
	allocationBarProviderRef,
	intervalDescriptions,
	userOffset,
	subheadingsOffset,
}: StaffViewPrintProps) => {
	return (
		<div className="h-full overflow-scroll relative">
			<div className="sticky top-0 w-max z-5 bg-white flex">
				<div
					className="flex align-items-start"
					style={{ width: SIDEBAR_SIZE, paddingRight: 20 }}
				>
					<Title>Print Layout</Title>
				</div>
				<div className="flex">
					{scenario.staffView.intervals.map((interval) => {
						return (
							<IntervalHeaderComponent
								key={"header-" + interval.index}
								intervalFragmentRef={interval}
							/>
						);
					})}
				</div>
			</div>

			<div></div>

			<div
				className="sticky left-0 z-4 bg-white"
				style={{
					width: SIDEBAR_SIZE,
					paddingRight: 20,
					paddingTop: HEADER_MARGIN,
				}}
			>
				{scenario.staffView.allocationGroups.map((allocationGroup) => {
					return allocationGroup.allocations.map((personAllocation, index) => {
						const height = calculateLaneHeight(personAllocation.lanes.length);
						const person = personAllocation.person;

						const showSubheader = showSubheaders && index === 0;

						const heightWithSubheader = height + (showSubheader ? SUBHEADER_SIZE : 0);

						return (
							<PersonAndBarsContainer>
								<div className="to-inline-block-print">
									{person ? (
										<CardContainer
											className="flex flex-column"
											key={"user-" + person.id}
											style={{
												minHeight: heightWithSubheader,
												maxHeight: heightWithSubheader,
												height: heightWithSubheader,
												marginBottom: MARGIN_BETWEEN_PEOPLE,
											}}
										>
											{showSubheader && (
												<h3
													className="mt-0"
													style={{
														fontSize: 18,
														marginBottom: 5,
													}}
												>
													{allocationGroup.assignmentRole?.name ??
														allocationGroup.project?.name}
												</h3>
											)}
											<PersonCard
												className="flex-grow-1 m-0 "
												style={{ height: "100%" }}
												scenarioFragmentRef={scenario}
												personFragmentRef={person}
												gapDaysOverride={personAllocation.gapDays}
												hideGapDays={
													allocationGroup.groupType === "project"
												}
												hideTotalVolume={false}
												scenarioUtilizationRef={
													scenario.utilizationWithStandAndEndDate
												}
											/>
										</CardContainer>
									) : null}
								</div>
								<Bars
									className="absolute top-0 left-0 z-0"
									style={{
										paddingLeft: SIDEBAR_SIZE + 23,
									}}
								>
									<div className="relative top-0 bg-white">
										{(() => {
											const showSubheader = showSubheaders && index === 0;
											const subheaderOffset = showSubheader
												? SUBHEADER_SIZE
												: 0;

											cumulativeSubheaderOffset =
												cumulativeSubheaderOffset + subheaderOffset;

											const lanes = personAllocation.lanes.flatMap(
												(lane, laneIndex) => {
													const laneTopOffset = 50 * laneIndex;

													return lane.allocations.map(
														(allocation, allocationIndex) => {
															const isGapAndHideIt =
																allocationGroup.groupType ===
																	"project" &&
																allocation.assignment?.id ===
																	undefined;

															return isGapAndHideIt ? null : (
																<AllocationBarProvider
																	key={
																		personAllocation.person!
																			.id +
																		"-lane-" +
																		laneIndex +
																		"-allocation-" +
																		allocationIndex +
																		"-provider"
																	}
																	ref={allocationBarProviderRef}
																	laneAllocationFragmentRef={
																		allocation
																	}
																	laneAllocationIds={lane.allocations.map(
																		(a) => a.id,
																	)}
																	intervalDescriptions={
																		intervalDescriptions
																	}
																	scenarioFragmentRef={scenario}
																	doesChainWithTheNextBar={checkIfAnAllocationChainsWithTheNextOne(
																		allocation,
																		lane.allocations[
																			allocationIndex + 1
																		],
																	)}
																>
																	<AllocationBarComponent
																		key={
																			personAllocation.person!
																				.id +
																			"-lane-" +
																			laneIndex +
																			"-allocation-" +
																			allocationIndex
																		}
																		topOffset={laneTopOffset}
																	/>
																</AllocationBarProvider>
															);
														},
													);
												},
											);

											const allLanesHeight = calculateLaneHeight(
												personAllocation.lanes.length,
											);

											userOffset =
												userOffset + allLanesHeight + MARGIN_BETWEEN_PEOPLE;

											return lanes;
										})()}
									</div>
								</Bars>
							</PersonAndBarsContainer>
						);
					});
				})}
			</div>

			<div
				className="absolute top-0 left-0 z-0"
				style={{
					paddingLeft: SIDEBAR_SIZE + 25,
				}}
			>
				<div className="relative bg-white">
					{intervalDescriptions.map((interval, index) => {
						return (
							<IntervalContainer
								printHeight={userOffset + subheadingsOffset}
								className="z-5 absolute top-0 bottom-0"
								key={"separator-" + index}
								style={{
									left: index * COLUMN_WIDTH,
									borderLeft: `1px solid #d2d7e1`,
									width: 1,
									...(interval?.fallsIntoCustomUtilizationWindow && {
										backgroundColor: "yellow",
										opacity: 0.2,
										width: COLUMN_WIDTH,
										borderLeft: `1px solid #d2d7e1`,
									}),
								}}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const PersonAndBarsContainer = styled.div`
	position: relative;
	z-index: 1;
	@media print {
		width: 150px;
	}
`;

const Bars = styled.div`
	position: absolute;
	top: 0;
`;

const CardContainer = styled.div`
	width: 280px;
`;

interface IntervalProps {
	printHeight: number;
}

const IntervalContainer = styled.div<IntervalProps>`
	height: ${(props) => props.printHeight}px;
	@media print {
		height: ${(props) => props.printHeight * 1.3}px !important ;
	}
`;

const Title = styled.div`
	@media print {
		display: none;
	}
`;
export default StaffViewPrintComponent;
