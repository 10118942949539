/**
 * @generated SignedSource<<acd4023f7404286f3c81e4fc32601f8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentProjectCard_AssignmentFragment$data = {
  readonly endDate: any;
  readonly id: string;
  readonly person: {
    readonly name: string;
  } | null;
  readonly project: {
    readonly id: string;
    readonly isDeactivated: boolean;
    readonly name: string;
  };
  readonly startDate: any;
  readonly validAssignmentRoles: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly weight: number | null;
  readonly " $fragmentType": "AssignmentProjectCard_AssignmentFragment";
};
export type AssignmentProjectCard_AssignmentFragment$key = {
  readonly " $data"?: AssignmentProjectCard_AssignmentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentProjectCard_AssignmentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignmentProjectCard_AssignmentFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDeactivated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentRole",
      "kind": "LinkedField",
      "name": "validAssignmentRoles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Assignment",
  "abstractKey": null
};
})();

(node as any).hash = "cf3ee7629965a9ba4df3ec3130f44a7b";

export default node;
