/**
 * @generated SignedSource<<2423d2fd1121fc2751c5558d968b822b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenerateAvailabilityForecastExcelReportInput = {
  clientMutationId?: string | null;
  countPossibleUtilizationNotPeople: boolean;
  filterByAssignmentTagsOpt?: ReadonlyArray<string> | null;
  filterByDivisionsOpt?: ReadonlyArray<string> | null;
  filterByProjectsOpt?: ReadonlyArray<string> | null;
  filterByRegionsOpt?: ReadonlyArray<string> | null;
  filterByStagesOpt?: ReadonlyArray<string> | null;
  fromOpt?: any | null;
  rows: ReadonlyArray<ForecastReportRow>;
  scenarioId: string;
  showProjects: boolean;
  toOpt?: any | null;
};
export type ForecastReportRow = {
  rolesRef: ReadonlyArray<string>;
};
export type GenerateReportButton_GenerateAvailabilityForecastExcelMutation$variables = {
  input: GenerateAvailabilityForecastExcelReportInput;
};
export type GenerateReportButton_GenerateAvailabilityForecastExcelMutation$data = {
  readonly Pdf: {
    readonly generateAvailabilityForecastExcelReport: {
      readonly file: {
        readonly name: string;
        readonly url: string | null;
      };
    } | null;
  };
};
export type GenerateReportButton_GenerateAvailabilityForecastExcelMutation = {
  response: GenerateReportButton_GenerateAvailabilityForecastExcelMutation$data;
  variables: GenerateReportButton_GenerateAvailabilityForecastExcelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateReportButton_GenerateAvailabilityForecastExcelMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PdfMutations",
        "kind": "LinkedField",
        "name": "Pdf",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GenerateAvailabilityForecastExcelReportPayload",
            "kind": "LinkedField",
            "name": "generateAvailabilityForecastExcelReport",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateReportButton_GenerateAvailabilityForecastExcelMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PdfMutations",
        "kind": "LinkedField",
        "name": "Pdf",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GenerateAvailabilityForecastExcelReportPayload",
            "kind": "LinkedField",
            "name": "generateAvailabilityForecastExcelReport",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc75c904bc06f7d5aac8e85a67959d76",
    "id": null,
    "metadata": {},
    "name": "GenerateReportButton_GenerateAvailabilityForecastExcelMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateReportButton_GenerateAvailabilityForecastExcelMutation(\n  $input: GenerateAvailabilityForecastExcelReportInput!\n) {\n  Pdf {\n    generateAvailabilityForecastExcelReport(input: $input) {\n      file {\n        name\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2b1af34892b253b25a8fa3f522017ea2";

export default node;
