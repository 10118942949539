import React, { Fragment, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SkillCategorySelect } from "@components/relay/SkillCategorySelect";
import { SkillsSelect } from "@components/relay/SkillsSelect";
import { FilterTag } from "@components/ui/filter-tag";
import { selectStaffViewFilters, setStaffViewFilters } from "@redux/StaffViewSlice";
import { applyFilter } from "@screens/project-view/parts/projects-grid-part/parts/projects-grid-part-content/projects-grid-part-content.utils";

export const PeopleFilterSkillsSelect = () => {
	const filters = useSelector(selectStaffViewFilters);
	const dispatch = useDispatch();
	return (
		<>
			<div className="field mr-2" style={{ minWidth: 250 }}>
				<label htmlFor={"people-filter-category-skills"}>Skills Category</label>
				<br />
				<Suspense>
					<SkillCategorySelect
						fieldValue={filters.peopleFilterSkillCategory}
						placeholder={"Filter skills by category"}
						updateField={(e) => {
							dispatch(
								setStaffViewFilters({
									...filters,
									peopleFilterSkillCategory: applyFilter(e),
								}),
							);
						}}
					/>
				</Suspense>
			</div>
			<div className="field mr-2" style={{ minWidth: 150 }}>
				<label htmlFor={"people-filter-skills"}>Skills</label>
				<br />
				<Suspense>
					<SkillsSelect
						fieldValue={filters.peopleFilterSkills}
						placeholder={"Filter by skills"}
						updateField={(e) => {
							dispatch(
								setStaffViewFilters({
									...filters,
									peopleFilterSkills: applyFilter(e),
								}),
							);
						}}
					/>
				</Suspense>
			</div>
		</>
	);
};

export const PeopleFilterSkillsReset = () => {
	const filters = useSelector(selectStaffViewFilters);
	const dispatch = useDispatch();
	if (!filters.peopleFilterSkills) return <Fragment />;
	return (
		<FilterTag
			icon={"pi pi-times"}
			tooltip={"People filter"}
			header={"Skills"}
			value={filters.peopleFilterSkills?.length + " selected"}
			onClick={() => {
				dispatch(
					setStaffViewFilters({
						...filters,
						peopleFilterSkills: undefined,
						peopleFilterSkillCategory: undefined,
					}),
				);
			}}
		/>
	);
};
