/**
 * @generated SignedSource<<f5132c73d252e28ab88961b0c960db75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnitSystem = "Imperial" | "Metric";
export type SetUnitSystemInput = {
  clientMutationId?: string | null;
  unitSystem: UnitSystem;
};
export type unitSystemForm_SetUnitSystemMutation$variables = {
  input: SetUnitSystemInput;
};
export type unitSystemForm_SetUnitSystemMutation$data = {
  readonly Admin: {
    readonly Auth: {
      readonly setUnitSystem: {
        readonly clientMutationId: string | null;
        readonly user: {
          readonly extension: {
            readonly unitSystem?: UnitSystem;
          };
        };
      } | null;
    };
  };
};
export type unitSystemForm_SetUnitSystemMutation = {
  response: unitSystemForm_SetUnitSystemMutation$data;
  variables: unitSystemForm_SetUnitSystemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitSystem",
      "storageKey": null
    }
  ],
  "type": "HarkinsUserExtensionAndId",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "unitSystemForm_SetUnitSystemMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetUnitSystemPayload",
                "kind": "LinkedField",
                "name": "setUnitSystem",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "unitSystemForm_SetUnitSystemMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetUnitSystemPayload",
                "kind": "LinkedField",
                "name": "setUnitSystem",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ffc29833fe347f9c82df71ad00770cfe",
    "id": null,
    "metadata": {},
    "name": "unitSystemForm_SetUnitSystemMutation",
    "operationKind": "mutation",
    "text": "mutation unitSystemForm_SetUnitSystemMutation(\n  $input: SetUnitSystemInput!\n) {\n  Admin {\n    Auth {\n      setUnitSystem(input: $input) {\n        user {\n          extension {\n            __typename\n            ... on HarkinsUserExtensionAndId {\n              unitSystem\n            }\n          }\n          id\n        }\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2dfc2fe66d988c97fd877380de51c88a";

export default node;
