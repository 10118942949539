/**
 * @generated SignedSource<<5ee12199a7a317c5514f1f048de938c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExportPeopleButton_executeDocumentBuilderMutation$variables = {
  name?: string | null;
};
export type ExportPeopleButton_executeDocumentBuilderMutation$data = {
  readonly DocumentBuilder: {
    readonly executeDocumentBuilder: {
      readonly file: {
        readonly url: string | null;
      };
    } | null;
  };
};
export type ExportPeopleButton_executeDocumentBuilderMutation = {
  response: ExportPeopleButton_executeDocumentBuilderMutation$data;
  variables: ExportPeopleButton_executeDocumentBuilderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "fileFormatKind",
        "value": "excel"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Literal",
                "name": "kind",
                "value": "GapReport"
              },
              {
                "kind": "Variable",
                "name": "name",
                "variableName": "name"
              }
            ],
            "kind": "ObjectValue",
            "name": "PeopleExport"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportPeopleButton_executeDocumentBuilderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentBuilderMutations",
        "kind": "LinkedField",
        "name": "DocumentBuilder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ExecuteDocumentBuilderPayload",
            "kind": "LinkedField",
            "name": "executeDocumentBuilder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExportPeopleButton_executeDocumentBuilderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentBuilderMutations",
        "kind": "LinkedField",
        "name": "DocumentBuilder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ExecuteDocumentBuilderPayload",
            "kind": "LinkedField",
            "name": "executeDocumentBuilder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "99178ca224752cdb22b28a1d51d3e99a",
    "id": null,
    "metadata": {},
    "name": "ExportPeopleButton_executeDocumentBuilderMutation",
    "operationKind": "mutation",
    "text": "mutation ExportPeopleButton_executeDocumentBuilderMutation(\n  $name: String\n) {\n  DocumentBuilder {\n    executeDocumentBuilder(input: {input: {PeopleExport: {name: $name, kind: GapReport}}, fileFormatKind: excel}) {\n      file {\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "de62310109bb4ae7c940346e2d228509";

export default node;
